<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar
          color="primary"
          dark
        >
          <span v-if="employeeName">{{ employeeName }}</span>
          <v-progress-circular
            v-else
            indeterminate
            dark
          ></v-progress-circular>
        </v-toolbar>
      </v-col>
      <v-col
        offset="4"
        cols="4">
        <v-autocomplete
          label="Restdays"
          v-model="restdays"
          :items="restdaysList"
          item-value="value"
          item-text="text"
          multiple
          size
          chips
          small-chips
          deletable-chips
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Rating"
          v-model="rating"
          chips
          small-chips
          auto-select-first
          :items="['fixed', 'daily']"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Monthly Rate"
          v-model="monthlyRate"
          @keyup="monthlyRateKeyup"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Daily Rate"
          v-model="dailyRate"
          @keyup="dailyRateKeyup"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Hourly Rate"
          v-model="hourlyRate"
          @keyup="hourlyRateKeyup"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="SSS"
          v-model="sss"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Pag-ibig"
          v-model="pagIbig"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Philhealth"
          v-model="philhealth"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn
          color="primary"
          dark
          block
          rounded
          @click="save"
        >
          Save
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn
          color="red"
          dark
          block
          rounded
          class="ml-2"
          @click="$router.push('/employees')"
        >
          Cancel
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import empRepository from '@/repositories/employee.repository'
import { postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const postDelay = searchDelay()
const monthlyRateDelay = searchDelay(300)
const dailyRateDelay = searchDelay(300)
const hourlyRateDelay = searchDelay(300)

// const snakeToCamel = str => str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

const getEmployeeName = ({ firstname, middlename, lastname }) => {
  let ret = ''
  if (firstname) {
    if (!middlename) {
      ret = `${firstname} ${lastname}`
    } else {
      ret = `${firstname} ${middlename.toUpperCase().substr(0, 1)}. ${lastname}`
    }
  }
  return ret
}

const inputVars = () => ({
  restdays: [],
  rating: null,
  monthlyRate: null,
  dailyRate: null,
  hourlyRate: null,
  sss: null,
  pagIbig: null,
  philhealth: null,
})

export default {
  name: 'CreateEmployeeRate',
  components: {
    // tooltipBtn: () => import('@/components/extra/TooltipBtn'),
  },
  data () {
    return {
      // ...getVars('emp'),
      ...postVars('emp'),
      ...inputVars(),
      employeeId: null,
      employeeName: null,
      monthlyRateLoading: false,
      dailyRateLoading: false,
      hourlyRateLoading: false,
      restdaysList: [
        { text: 'Sun', value: 0 },
        { text: 'Mon', value: 1 },
        { text: 'Tue', value: 2 },
        { text: 'Wed', value: 3 },
        { text: 'Thu', value: 4 },
        { text: 'Fri', value: 5 },
        { text: 'Sat', value: 6 },
      ],
    }
  },
  created () {
    // this.getEmployee()
  },
  computed: {

  },
  methods: {
    // getEmployee () {
    //   if (!this.empGetting) {
    //     this.empGetting = true
    //     getDelay(() => {
    //       this.empGetErrors = []
    //       empRepository.get(this.$route.params.employee)
    //         .then(({ data }) => { this.employeeName = getEmployeeName(data) })
    //         .catch(e => { this.empGetErrors = errorHandler(e) })
    //         .then(() => { this.empGetting = false })
    //     })
    //   }
    // },
    save () {
      if (!this.empPosting) {
        this.empPosting = true
        const data = {
          restdays: this.restdays.join('-'),
          rating: this.rating,
          monthly_rate: this.monthlyRate,
          daily_rate: this.dailyRate,
          hourly_rate: this.hourlyRate,
          sss: this.sss,
          pag_ibig: this.pagIbig,
          philhealth: this.philhealth,
        }
        postDelay(() => {
          this.empPostErrors = []
          empRepository.storeRate(this.employeeId, data)
            .then(() => {
              this.reset({ ...inputVars(), employeeId: null, employeeName: null })
              this.$emit('close-dialog')
            })
            .catch(e => { this.empPostErrors = errorHandler(e) })
            .then(() => { this.empPosting = false })
        })
      }
    },
    populateInputs ({ employeeId, employeeName }) {
      this.employeeId = employeeId
      this.employeeName = employeeName
    },
    reset (vars) {
      Object.keys(vars).forEach(e => {
        this[e] = vars[e]
      })
    },
    monthlyRateKeyup () {
      this.dailyRateLoading = true
      this.hourlyRateLoading = true
      monthlyRateDelay(() => {
        this.dailyRateLoading = false
        this.hourlyRateLoading = false
        this.dailyRate = (this.monthlyRate / 26).toFixed(2)
        this.hourlyRate = (this.dailyRate / 8).toFixed(2)
      })
    },
    dailyRateKeyup () {
      this.monthlyRateLoading = true
      this.hourlyRateLoading = true
      dailyRateDelay(() => {
        this.monthlyRateLoading = false
        this.hourlyRateLoading = false
        this.monthlyRate = (this.dailyRate * 26).toFixed(2)
        this.hourlyRate = (this.dailyRate / 8).toFixed(2)
      })
    },
    hourlyRateKeyup () {
      this.dailyRateLoading = true
      this.monthlyRateLoading = true
      hourlyRateDelay(() => {
        this.dailyRateLoading = false
        this.monthlyRateLoading = false
        this.dailyRate = (this.hourlyRate * 8).toFixed(2)
        this.monthlyRate = (this.dailyRate * 26).toFixed(2)
      })
    },
  },
}
</script>
